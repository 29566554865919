import React, { FC, useRef } from 'react';
import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';

const NewsletterThanks: FC<PageContent.INewsletterThank> = ({
  thankTitle,
  thankLogo,
  thankLogoAlt,
  shopLink,
  brands,
  promoCode,
  thankText,
  partnerLogo,
  partnerLogoAlt,
  copyLabel,
}) => {
  const promoEl = useRef<HTMLInputElement>(null);

  const copyToClipboard = () => {
    promoEl?.current?.select();
    document.execCommand('copy');
  };

  return (
    <div className="newsletter__step2">
      <div className="newsletter__banner">
        {promoCode && thankLogo?.fallbackUrl ? (
          <GatsbyImage className="newsletter__thank-logo" fluid={thankLogo} alt={thankLogoAlt} />
        ) : null}
        {!promoCode && thankTitle?.length ? (
          <strong className="newsletter__title">
            {thankTitle.map(({ value }) => (
              <DangerouslySetInnerHtml
                element="span"
                key={value}
                className="newsletter__title-line"
                html={value}
              />
            ))}
          </strong>
        ) : null}
      </div>

      <div className="newsletter__ecommerce">
        {promoCode ? (
          <div className="newsletter__promo">
            {thankText ? (
              <DangerouslySetInnerHtml className="newsletter__form-question" html={thankText} />
            ) : null}
            <div className="newsletter__promo-line">
              <div className="newsletter__promo-code-wrap">
                <input
                  readOnly
                  ref={promoEl}
                  className="newsletter__promo-code"
                  defaultValue={promoCode}
                />
              </div>
              <Button clickHandler={copyToClipboard} variant="link">
                <IconCustom icon="file_copy" />
                {copyLabel}
              </Button>
            </div>
          </div>
        ) : null}
        {partnerLogo?.fallbackUrl ? (
          <GatsbyImage
            className="newsletter__partner-logo"
            fluid={partnerLogo}
            alt={partnerLogoAlt}
          />
        ) : null}
        {shopLink?.length ? (
          <Button to={shopLink[0].url} variant="pink">
            {shopLink[0].name}
          </Button>
        ) : null}
      </div>
      {brands?.length ? (
        <div className="newsletter__brands">
          {brands.map(({ properties: { imageAlt, link, image } }) =>
            image.fallbackUrl ? (
              <div key={imageAlt} className="newsletter__brands-item">
                <a href={link?.[0].url} target="_blank" rel="noreferrer">
                  <GatsbyImage className="newsletter__brand-logo" fluid={image} alt={imageAlt} />
                </a>
              </div>
            ) : null
          )}
        </div>
      ) : null}
    </div>
  );
};

export default NewsletterThanks;
