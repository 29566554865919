interface FontPreload {
  rel: string;
  as: string;
  crossOrigin: string;
  href: string;
  type: string;
}

const getPreloadLinks = ({ href, type }): FontPreload => ({
  rel: 'preload',
  as: 'font',
  crossOrigin: 'anonymous',
  href,
  type,
});

export const preloadFontLinks = [].map(getPreloadLinks);

export default preloadFontLinks;
