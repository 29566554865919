import { useEffect } from 'react';

interface IClickOutside {
  (reference: RefObject<HTMLDivElement>, callback?: (x?: string) => void): void;
}

const useClickOutside: IClickOutside = (reference, callback) => {
  const handleClick = (e) => {
    if (
      reference.current &&
      !reference.current.contains(e.target) &&
      e.target.nodeName !== 'BUTTON'
    ) {
      callback && callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};
export default useClickOutside;
