import React, { FC } from 'react';

import { IIconCustom } from './models';
import './IconCustom.scss';

const IconCustom: FC<IIconCustom> = ({ icon }) => (
  <span aria-hidden="true" className={`gs-icon-custom icon-${icon}`} />
);

export default IconCustom;
