import React, { FC, useEffect, useState } from 'react';
import Button from 'components/common/Button';
import './LegalPopup.scss';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

const LegalPopup: FC<PageContent.ILegalPopup> = ({
  legalPopupTitle,
  legalPopupDescription,
  legalPopupButton,
  legalPopupButtonAria,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
    sessionStorage.setItem('legalPopup', 'closed');
  };

  useEffect(() => {
    if (!sessionStorage.getItem('legalPopup')) {
      setIsOpen(true);
    }
  }, []);

  return isOpen ? (
    <div className="legalPopup">
      <div className="legalPopup__popup">
        <h2 className="legalPopup__title">{legalPopupTitle}</h2>
        <DangerouslySetInnerHtml className="legalPopup__content" html={legalPopupDescription} />
        <div className="legalPopup__actions">
          <Button variant="pink" ariaLabel={legalPopupButtonAria} clickHandler={onClose}>
            {legalPopupButton}
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default LegalPopup;
