import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IExternalLink } from './models';

const ExternalLink: FC<IExternalLink> = ({
  to,
  children,
  target,
  ariaLabel,
  activeClassName,
  linkState,
  ...rest
}) => {
  return target === '_blank' ? (
    <a href={to} rel="noopener nofollow" aria-label={ariaLabel} {...{ target, ...rest }}>
      {children}
    </a>
  ) : (
    <Link
      {...{ to, activeClassName, ...(linkState && { state: linkState }), ...rest }}
      aria-label={ariaLabel}
    >
      {children}
    </Link>
  );
};

export default ExternalLink;
