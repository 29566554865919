import React, { FC, useRef } from 'react';
import loadable from '@loadable/component';

import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';

import useClickOutside from 'hooks/useClickOutside';
import { SearchFormProps } from './model';
import './SearchForm.scss';

const SearchForm: FC<SearchFormProps> = ({
  placeholder,
  onClose,
  ariaSearchSubmit,
  ariaSearch,
  ariaClear,
  ariaSearchInput,
}) => {
  const node = useRef<HTMLDivElement>(null);
  useClickOutside(node, onClose);

  const LazySearchInput = () => {
    const SearchInput = loadable(() => import('components/SearchInput'));

    return (
      <SearchInput
        searchPlaceholder={placeholder}
        ariaClear={ariaClear}
        ariaSearchSubmit={ariaSearchSubmit}
        ariaSearchInput={ariaSearchInput}
        submitCallback={onClose}
        searchBtnOutside
      />
    );
  };

  return (
    <div ref={node} className="search-form">
      <div className="search-form__decor">
        <IconCustom icon="search" />
      </div>
      <LazySearchInput />
      <Button
        variant="icon"
        clickHandler={onClose}
        ariaLabel={ariaSearch}
        classes="search-form__close"
      >
        <IconCustom icon="close" />
      </Button>
    </div>
  );
};

export default SearchForm;
