import React from 'react';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash/isEmpty';

import additionalSeoProps from './constants';

const AdditionalSeoAttributes = () =>
  !isEmpty(additionalSeoProps) ? <Helmet {...additionalSeoProps} /> : null;

export default AdditionalSeoAttributes;
