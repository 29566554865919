import React, { FC, useState } from 'react';
import NavigationItem from './NavigationItem';

import { INavigationDropdown } from './model';

const Dropdown: FC<INavigationDropdown> = ({ subNavigation, handleBlur }) => {
  const [currentItem, setCurrentItem] = useState('');

  return (
    <ul className="gs-navigation__dropdown">
      {subNavigation.map((navItem) => (
        <NavigationItem
          key={navItem.properties.label}
          {...{ currentItem, setCurrentItem, handleBlur, Dropdown, ...navItem }}
          classes="gs-navigation__dropdown-item"
        />
      ))}
    </ul>
  );
};

export default Dropdown;
