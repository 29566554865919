import React, { FC, useEffect, useState, useCallback } from 'react';
import Cookies from 'js-cookie';
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import classNames from 'classnames';

import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';

import { isBrowser } from 'utils/browser';
import { pushSHAtoDataLayer } from 'utils/GTMhelpers';
import { gtmService } from 'services/gtmService';

import NewsletterForm from './NewsletterForm';
import NewsletterThanks from './NewsletterThanks';

import './NewsLetter.scss';

interface INewsletter extends PageContent.INewsletterForm, PageContent.INewsletterThank {
  ariaClose: string;
  consentCheckboxes: {
    businessId: string;
    consentDescription: string;
    revisionId: string;
  }[];
}

interface ISubmitForm {
  (): void;
}

const lambdaUrl = process.env.GATSBY_CDP_LAMBDA_URL;

const NewsLetter: FC<INewsletter> = ({
  question,
  label1,
  label2,
  thankTitle,
  thankLogo,
  thankLogoAlt,
  shopLink,
  brands,
  promoCode,
  thankText,
  partnerLogo,
  partnerLogoAlt,
  afterTitle,
  beforeTitle,
  title,
  formTitle,
  namePlaceholder,
  emailPlaceholder,
  submitText,
  ariaSubmit,
  ariaClose,
  copyLabel,
  consentCheckboxes,
  errorName,
  errorEmail,
  errorServer,
  lang,
  wayinFormSrc,
  wayinFormId,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isSent, setIsSent] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const isWayinForm = !!wayinFormSrc?.length && !!wayinFormId?.length;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subscribe: false,
    emailError: false,
    nameError: false,
    signUpError: false,
  });
  const today = new Date();

  useEffect(() => {
    if (!isWayinForm) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://xd.wayin.com/ui/ngx.embed.min.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [isWayinForm]);

  const sendRequest = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const gRecaptchaResponse = await executeRecaptcha('newsletterSignUp');

    const resBody = {
      cdp: {
        programCode: 'RBGBRGAV',
        brandOrgCode: 'RBGBRGAV',
        accountSource: 'GBRGAVECOMCUST',
      },
      data: {
        TierCode: 'RBGBRGAV',
        Emails: [
          {
            EmailAddress: formData.email,
            DeliveryStatus: 'G',
          },
        ],
        JsonExternalData: {
          UnmappedAttributes: {
            email: formData.email,
            name: formData.name,
            optinemail: true,
            lead_customer_brand_opt_in: true,
            rb_commercial_brand_opt_in: true,
            brand: 'Gaviscon',
            channel_source: 'Gaviscon WebSite',
            campaign_type_name: 'Data_Capture_Gaviscon_10_precent_off_2020',
            fromUrl: 'gaviscon.co.uk',
            formId: 'newsletterForm',
            formTime: `${today.toISOString()}`,
            regTime: `${today}`,
          },
          Agreements: consentCheckboxes?.length
            ? consentCheckboxes.map(({ businessId, revisionId, consentDescription }) => ({
                BusinessId: businessId,
                RevisionId: revisionId,
                ConsentDesc: consentDescription,
                MandatoryInd: consentDescription !== 'PP',
                ConsentAcceptedInd: consentDescription !== 'PP',
              }))
            : [],
        },
      },
    };

    const body = {
      ...resBody,
      recaptchaResponse: gRecaptchaResponse,
    };

    const response = await fetch(lambdaUrl!, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (response.status >= 200 && response.status <= 299) {
      await response.json();
    } else if (!response.ok) {
      throw new Error('Server error');
    }
  }, [formData]);

  const submitForm: ISubmitForm = () =>
    sendRequest()
      .then(() => {
        gtmService.emitGenerateLead(gtmService.formNames.newsletter);
        pushSHAtoDataLayer(formData.email);
        setIsSent(true);
        Cookies.set('NEWSLETTER_HAS_SUBMITED', '1', { expires: 365 });
      })
      .catch(() => {
        setFormData({
          ...formData,
          signUpError: true,
        });
      });

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (isBrowser()) {
      Cookies.set('NEWSLETTER_HAS_SHOWN', '1', { expires: 7 });
    }
    setIsVisible(false);

    if (isWayinForm) {
      window.location.reload();
    }
  };

  const stopEvents = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  useEffect(() => setIsVisible(true), []);

  useEffect(() => {
    if (isVisible) {
      document.querySelector('body')?.classList.add('no-scroll');
    } else {
      document.querySelector('body')?.classList.remove('no-scroll');
    }
    const addScroll = () => {
      document.querySelector('body')?.classList.remove('no-scroll');
    };

    return addScroll;
  }, [isVisible]);

  return isVisible ? (
    <div className="newsletter" data-testid="newsletter" role="presentation" onClick={handleClose}>
      <div
        className={classNames('newsletter__popup', { 'newsletter__popup--wayin': isWayinForm })}
        role="presentation"
        onClick={stopEvents}
      >
        <Button
          ariaLabel={ariaClose}
          classes="newsletter__popup-close"
          clickHandler={handleClose}
          variant="icon"
        >
          <IconCustom icon="close" />
        </Button>
        {isWayinForm ? (
          <iframe
            title="Wayin form"
            src={wayinFormSrc}
            frameBorder="0"
            scrolling="no"
            width="100%"
            height="100%"
            id={wayinFormId}
          />
        ) : !isSent ? (
          <NewsletterForm
            {...{
              beforeTitle,
              question,
              label1,
              label2,
              afterTitle,
              title,
              formTitle,
              namePlaceholder,
              emailPlaceholder,
              submitText,
              ariaSubmit,
              errorName,
              errorEmail,
              errorServer,
              lang,
            }}
            formData={formData}
            setFormData={setFormData}
            onSubmit={submitForm}
          />
        ) : (
          <NewsletterThanks
            {...{
              thankTitle,
              thankLogo,
              thankLogoAlt,
              shopLink,
              brands,
              promoCode,
              thankText,
              partnerLogo,
              partnerLogoAlt,
              copyLabel,
            }}
          />
        )}
      </div>
    </div>
  ) : null;
};

const FormWrapper = ({ newsletter }) => {
  const [showWrapper, setShowWrapper] = useState(false);
  const showTimeout = parseInt(newsletter.showTimeout, 10);

  useEffect(() => {
    if (
      isBrowser() &&
      !Cookies.get('NEWSLETTER_HAS_SHOWN') &&
      !Cookies.get('NEWSLETTER_HAS_SUBMITED')
    ) {
      const t = setTimeout(() => {
        setShowWrapper(true);
      }, showTimeout || 30000);

      return () => {
        clearTimeout(t);
      };
    }
  }, []);

  return showWrapper ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
    >
      <NewsLetter {...newsletter} />
    </GoogleReCaptchaProvider>
  ) : null;
};

export default FormWrapper;
