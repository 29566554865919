import React, { FC, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { navigate } from 'gatsby';
import { globalHistory as history } from '@reach/router';
import { SPACEBAR_KEY_CODE } from 'utils/constants';
import { parseBoolean } from 'utils/parseHelpers';
import Button from '../Button';
import IconCustom from '../IconCustom';

import './LanguageSelector.scss';

const LanguageSelector: FC<PageContent.ILanguageSelector> = React.memo(
  ({ type, redirectToHome, title, langs, currentLangCode }) => {
    const { location } = history;
    const currentLang = langs?.find(
      (lang) => lang.properties.code.toLowerCase() === currentLangCode
    );
    const { prefix: currentPrefix, title: currentLangName } = currentLang?.properties;

    const handleChangeLang = (e: React.MouseEvent, langName: string, code: string): void => {
      const newPath = parseBoolean(redirectToHome)
        ? e.target.value
        : location.pathname.replace(currentPrefix, e.target.value) + location.search;
      navigate(newPath);

      if (currentLangCode !== code) {
        window.OneTrust?.changeLanguage(code);
      }
    };
    const [isOpen, setIsOpen] = useState(false);
    const timer = useRef<Timeout>(null);
    const containerCssClassNames = classnames('lang-selector', {
      'lang-selector--is-open': isOpen,
      'lang-selector--drop': type !== 'list',
      'lang-selector--list': type === 'list',
    });
    const listCssClassNames = classnames({
      'lang-selector__list': type === 'list',
      'lang-selector__menu': type !== 'list',
    });
    const handleKeyPressed = (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.keyCode === SPACEBAR_KEY_CODE) {
        e.preventDefault();
        setIsOpen(!isOpen);
      }
    };

    useEffect(() => clearTimeout(timer.current), []);

    const handleBlur = () => {
      timer.current = setTimeout(() => setIsOpen(false), 2000);
    };
    const handleFocus = () => {
      clearTimeout(timer.current);
    };

    return (
      <div className={containerCssClassNames} data-testid="language-selector">
        {type === 'list' && title ? (
          <strong className="lang-selector__title">{title}</strong>
        ) : null}
        {type !== 'list' ? (
          <Button
            ariaLabel={title}
            classes="lang-selector__opener"
            onKeyDown={handleKeyPressed}
            variant="link"
          >
            {currentLangName}
            <IconCustom icon="chevron-down" />
          </Button>
        ) : null}
        <ul className={listCssClassNames}>
          {langs?.length
            ? langs.map(({ properties: { code, ariaLabel, prefix, title: langName } }) => (
                <li className={classnames({ active: currentLangCode === code })} key={langName}>
                  <Button
                    ariaLabel={ariaLabel}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    clickHandler={(e) => handleChangeLang(e, langName, code)}
                    value={prefix}
                    variant="link"
                    tabIndex={isOpen ? undefined : -1}
                  >
                    {langName}
                  </Button>
                </li>
              ))
            : null}
        </ul>
      </div>
    );
  }
);

export default LanguageSelector;
