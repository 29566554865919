import React, { FC, useState } from 'react';
import Button from 'components/common/Button';
import classnames from 'classnames';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { validateEmail, validateName } from 'utils/helpers';

const NewsletterForm: FC<PageContent.INewsletterForm> = ({
  beforeTitle,
  question,
  label1,
  label2,
  afterTitle,
  title,
  formTitle,
  namePlaceholder,
  emailPlaceholder,
  submitText,
  ariaSubmit,
  formData,
  setFormData,
  onSubmit,
  errorName,
  errorEmail,
  errorServer,
  lang,
}) => {
  const [isFilled, seIsFilled] = useState(false);
  const date = new Date();
  const localDate = date.toLocaleDateString(lang, {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
  });

  const emailCssClassName = classnames('newsletter__form-control', {
    'newsletter__form-control--invalid': formData.emailError,
  });

  const nameCssClassName = classnames('newsletter__form-control', {
    'newsletter__form-control--invalid': formData.nameError,
  });

  const checkRequired = () => {
    const filled = Boolean(formData.name && formData.email);
    seIsFilled(filled);
  };

  const handleInputChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { name, checked, type, value } = e.currentTarget;
    const normalValue = type === 'checkbox' ? checked : value;

    setFormData((oldState) => ({
      ...oldState,
      [`${name}`]: normalValue,
    }));

    checkRequired();
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const emailError = !validateEmail(formData.email);
    const nameError = !validateName(formData.name);

    setFormData((oldFormData) => ({
      ...oldFormData,
      emailError,
      nameError,
    }));
    if (!emailError && !nameError) {
      onSubmit && onSubmit();
    }
  };

  return (
    <form action="#" onSubmit={handleSubmit} className="newsletter__step1">
      <div className="newsletter__banner">
        {beforeTitle ? <p className="newsletter__title-prefix">{beforeTitle}</p> : null}
        {title.length ? (
          <strong className="newsletter__title">
            {title.map(({ value }) => (
              <DangerouslySetInnerHtml
                key={value}
                element="span"
                className="newsletter__title-line"
                html={value}
              />
            ))}
          </strong>
        ) : null}
        {afterTitle ? <p className="newsletter__title-suffix">{afterTitle}</p> : null}
      </div>

      <div className="newsletter__form">
        {formTitle ? (
          <DangerouslySetInnerHtml html={formTitle} className="newsletter__form-title" />
        ) : null}
        <div className="newsletter__form-controls">
          <div className={nameCssClassName}>
            <label className="newsletter__form-input-label" htmlFor="name">
              {namePlaceholder}
            </label>
            <input
              id="name"
              onChange={handleInputChange}
              value={formData.name}
              name="name"
              type="text"
            />
            {formData.nameError ? (
              <p className="newsletter__validation-error">{errorName}</p>
            ) : null}
          </div>
          <div className={emailCssClassName}>
            <label className="newsletter__form-input-label" htmlFor="email">
              {emailPlaceholder}
            </label>
            <input
              id="email"
              onChange={handleInputChange}
              value={formData.email}
              name="email"
              type="text"
            />
            {formData.emailError ? (
              <p className="newsletter__validation-error">{errorEmail}</p>
            ) : null}
          </div>
        </div>
        {question ? (
          <DangerouslySetInnerHtml className="newsletter__form-question" html={question} />
        ) : null}
        {label1 ? (
          <label htmlFor="check1" className="newsletter__form-check">
            <input
              required
              id="check1"
              onChange={handleInputChange}
              checked={formData.subscribe}
              name="subscribe"
              type="checkbox"
            />
            <DangerouslySetInnerHtml className="newsletter__form-label" html={label1} />
          </label>
        ) : null}
        {label2 ? (
          <label htmlFor="check2" className="newsletter__form-check">
            <DangerouslySetInnerHtml
              className="newsletter__form-label"
              html={label2.replace(/{today}/g, localDate)}
            />
          </label>
        ) : null}
        <div className="newsletter__submit">
          {formData.signUpError ? <p className="newsletter__server-error">{errorServer}</p> : null}
          <Button disabled={!isFilled} ariaLabel={ariaSubmit} variant="pink" type="submit">
            {submitText}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default NewsletterForm;
