import sha256 from 'sha256';
import { isBrowser } from './browser';

export const pushSHAtoDataLayer = (email: string) => {
  if (isBrowser()) {
    window.dataLayer && window.dataLayer.push({ email_sha256: sha256(email) });
    window.Krux &&
      window.Krux(
        'ns:reckittbenckiserukhealth',
        'page:load',
        function (err) {
          console.log(`krux err: ${err}`);
        },
        { pageView: true }
      );
  }
};

export default { pushSHAtoDataLayer };
