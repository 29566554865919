import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { DISCLAIMER_HAS_SHOWN } from 'utils/constants';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import './DisclaimerPopup.scss';
import IconCustom from 'components/common/IconCustom';

const DisclaimerPopup: FC<PageContent.IDisclaimerPopup> = ({
  opener,
  ariaOpener,
  ariaClose,
  description,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isOpen) {
      Cookies.set(DISCLAIMER_HAS_SHOWN, '1', { expires: 7 });
    }
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (!Cookies.get(DISCLAIMER_HAS_SHOWN)) {
      setIsOpen(true);
    }
  }, []);

  return (
    <div className="disclaimer" data-testid="disclaimer-popup">
      {opener ? (
        <Button
          variant="link"
          classes="disclaimer__opener"
          ariaLabel={ariaOpener}
          clickHandler={togglePopup}
        >
          {opener}
        </Button>
      ) : null}

      {isOpen ? (
        <div className="disclaimer__popup">
          <Button
            variant="icon"
            classes="disclaimer__close"
            ariaLabel={ariaClose}
            clickHandler={togglePopup}
          >
            <IconCustom icon="close" />
          </Button>
          <div className="disclaimer__scroll">
            {description ? (
              <DangerouslySetInnerHtml className="disclaimer__text" html={description} />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DisclaimerPopup;
